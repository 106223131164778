import React from 'react';
import '../styles/Services.css'; // Assurez-vous d'avoir un fichier Services.css pour styliser votre contenu
import video from '../assets/videos/video_services.mp4'
import { Link } from 'react-router-dom';

const Services = () => {
    return (
        <div className="services-container">
            <h2 className="services-title">NOS SERVICES</h2>
            <div className="services-list">
                <div className="service-block">
                    <h3>NETTOYAGE COURANT DES BÂTIMENTS ET ENTREPÔTS</h3>
                    <p>FINE SERVICES vous apporte pour les bâtiments et entrepôts un nettoyage adapté aux types de salissures spécifiques et aux revêtements particuliers.</p>
                </div>
                <div className="service-block">
                    <h3>RENOVATION INTERIEURE</h3>
                    <p>Rénovation de maison et d’appartement, travaux d’aménagement de combles, optimisation de l’isolation, rénovation de salle de bain, de cuisine, pose de parquet, carrelage, peinture…</p>
                </div>
                <div className="service-block">
                    <h3>DEBARRAS</h3>
                    <p>FINE SERVICES propose un débarras professionnel de caves, expulsions et parkings, garantissant une intervention efficace et respectueuse des délais.</p>
                </div>
                <div className="service-block">
                    <h3>ENTRETIEN DES ESPACES VERTS</h3>
                    <p>Qui aurait envie de passer son temps dans un extérieur mal entretenu, rempli de mauvaises herbes ? Votre jardin nécessite une attention particulière, FINE SERVICES s'en occupe pour vous !</p>
                </div>
                <div className="service-block">
                    <h3>NETTOYAGE EXTREME(DIOGENES)</h3>
                    <p>Vous êtes confronté à un nettoyage de maison extrême en raison d'une accumulation excessive de biens ou de déchets? Notre équipe est spécialisée dans le nettoyage de maisons de type Diogène. Nous comprenons les défis uniques liés à ce type de nettoyage et nous sommes là pour vous aider à retrouver un environnement propre et sûr.</p>
                </div>
                <div className="service-block">
                    <h3>NETTOYAGE DE VOS BASES VIE MODULAIRES ET BUNGALOWS DE CHANTIER</h3>
                    <p>Vous cherchez à installer des bases vie modulaires pour vos équipes sur vos chantiers ?
                        FINE SERVICES est là pour vous. Nous offrons un service complet d'entretien et de nettoyage pour assurer
                        le confort et la propreté de vos lieux de vie et de repos. Notre offre spécifique pour les chantiers tient
                        compte des exigences du secteur et garantit une désinfection complète des équipements sanitaires et des espaces de vie.
                        Faites confiance
                        à FINE SERVICES pour maintenir un environnement propre et confortable sur vos chantiers.</p>
                </div>
            </div>
            <video controls className='video_service1'>
                <source src={video} type="video/mp4" />
            </video>
            <div className='container-text-services'>
                <p className='description-nos-services'>FINE SERVICES est votre partenaire de confiance pour la rénovation et le nettoyage en Île-de-France. Notre équipe dévouée offre des services impeccables pour redonner vie à vos espaces. Que ce soit pour rénover une cuisine ou une salle de bains, ou pour effectuer un nettoyage en profondeur, nous sommes là pour vous. Contactez-nous dès aujourd'hui pour découvrir comment nous pouvons transformer votre espace en un lieu impeccable et accueillant.</p>
            </div>
            <button className="btn-learn-more">
                <Link to='/Services'>En savoir plus</Link>
            </button>
        </div>
    );
};

export default Services;
