import React from 'react';
import { Link } from 'react-router-dom';
import { FaFacebook, FaInstagram, FaTiktok } from 'react-icons/fa';
import '../styles/Footer.css';

function Footer() {
    return (
        <footer className="footer">
            <div className="footer-content">
                <address className="footer-section">
                    <p><strong>Adresse:</strong> 102 BOULEVARD GABRIEL PERI 91170, VIRY-CHATILLON France</p>
                    <p><strong>Email:</strong> contact@fineservices.fr</p>
                    <p><strong>Contact:</strong> 01 69 03 34 63</p>
                </address>
                <Link to='/Contact' className="contact1-button">Contactez-nous</Link>
                <div className="social-links">
                    <a href="https://www.facebook.com/Fineservices91?mibextid=JRoKGi" target="_blank" rel="noopener noreferrer"><FaFacebook /> Facebook</a>
                    <a href="https://www.instagram.com/fineservices91?igsh=eGQ0dmhkbGticjU4" target="_blank" rel="noopener noreferrer"><FaInstagram /> Instagram</a>
                    <a href="https://www.tiktok.com/@fineservices?_t=8lYCW6RwhTq&_r=1" target="_blank" rel="noopener noreferrer"><FaTiktok /> TikTok</a>
                </div>
            </div>
            <hr className="divider" />
            <p className="copyright">© COPYRIGHT 2024 BY FINE SERVICES SARL</p>
        </footer>
    );
}

export default Footer;
