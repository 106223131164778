import React, { useState, useEffect } from 'react';
import '../styles/Carousel.css';
import image1 from '../assets/img/image 1.jpg';
import image2 from '../assets/img/image 2.jpg';
import image3 from '../assets/img/image 3.jpg';
import image4 from '../assets/img/image 4.jpg';
import image5 from '../assets/img/image 5.jpg';
import image6 from '../assets/img/image 6.jpg';
import image7 from '../assets/img/image 7.jpg';

const Carousel = () => {
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const images = [image1, image2, image3, image4, image5, image6, image7];

    useEffect(() => {
        const intervalId = setInterval(() => {
            setCurrentImageIndex(prevIndex => (prevIndex === images.length - 1 ? 0 : prevIndex + 1));
        }, 5000);

        return () => clearInterval(intervalId);
    }, [images.length]);

    return (
        <div>
            <h1 className='entete'>SERVICES DE NETTOYAGE ET RÉNOVATION INTÉRIEURE</h1>
            <p className='paragraphe'>
                Spécialisé dans le nettoyage, la rénovation courante des bâtiments et l'entretien des espaces verts,
                notre entreprise se distingue par le professionnalisme de ses agents et leur expertise.
            </p>
            <div className="carousel-container">
                <div className="carousel">
                    {images.map((image, index) => (
                        <img
                            key={index}
                            src={image}
                            alt={`Slide ${index + 1}`}
                            className={index === currentImageIndex ? 'visible' : 'hidden'}
                            loading="lazy"
                        />
                    ))}
                </div>
                <div className="indicator">
                    {images.map((_, index) => (
                        <span
                            key={index}
                            className={index === currentImageIndex ? 'active' : ''}
                            onClick={() => setCurrentImageIndex(index)}
                        ></span>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default Carousel;
