import React, { useState, useEffect } from 'react';
import '../styles/Témoignages.css'; // Assurez-vous d'avoir un fichier Testimonials.css pour styliser votre composant

const Témoignages = () => {
    const [currentTestimonialIndex, setCurrentTestimonialIndex] = useState(0);

    // Défilement automatique des témoignages toutes les 5 secondes
    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentTestimonialIndex((prevIndex) => (prevIndex + 1) % testimonials.length);
        }, 5000);

        return () => clearInterval(interval);
    }, []);

    const testimonials = [
        {
            name: "Marc",
            text: "Merci à Fine Services pour le travail exceptionnel effectué dans ma maison. Ils ont rénové chaque pièce avec soin et professionnalisme. Je recommande vivement leurs services à tous ceux qui recherchent des travaux de rénovation intérieure de haute qualité."
        },
        {
            name: "Marie",
            text: "Merci à Fine Services pour avoir transformé mon espace de vie. Leur équipe a fait un travail incroyable pour rénover mon appartement. Je suis très satisfait du résultat final et je les recommande à tous mes amis et ma famille."
        },
        {
            name: "Clara",
            text: "Je tiens à exprimer ma gratitude à Fine Services pour leur excellent travail. Ils ont rénové mon bureau de manière spectaculaire, en le transformant en un espace moderne et fonctionnel. Je les recommande vivement à tous ceux qui ont besoin de services de rénovation de qualité."
        },
        {
            name: "Émilie",
            text: "Je remercie Fine Services pour leur travail exceptionnel dans la rénovation de mon jardin. Ils ont transformé mon espace extérieur en un endroit magnifique et accueillant. Je les recommande à tous ceux qui ont besoin de services de rénovation d'espace vert de qualité."
        },
        {
            name: "Jean",
            text: "Un grand merci à Fine Services pour leur excellent travail dans la rénovation de ma salle de bains. Ils ont fait un travail exceptionnel et je suis très satisfait du résultat final. Je les recommande à tous ceux qui recherchent des services de rénovation de salle de bains de haute qualité."
        },
        {
            name: "Sophie",
            text: "Je suis extrêmement reconnaissant envers Fine Services pour leur excellent travail dans la rénovation de mon bureau. Ils ont fait un travail exceptionnel pour transformer mon espace de travail et je les recommande vivement à tous ceux qui ont besoin de services de rénovation de bureau de qualité."
        },
        {
            name: "Jacques",
            text: "Merci à Fine Services pour leur travail remarquable dans la rénovation de ma cuisine. Ils ont fait un travail exceptionnel pour transformer ma cuisine en un espace moderne et fonctionnel. Je les recommande à tous ceux qui ont besoin de services de rénovation de cuisine de haute qualité."
        },
        {
            name: "Louise",
            text: "Un grand merci à Fine Services pour leur excellent travail dans la rénovation de mon salon. Ils ont fait un travail exceptionnel pour transformer mon espace de vie et je les recommande vivement à tous ceux qui ont besoin de services de rénovation de salon de qualité."
        },
        {
            name: "Luc",
            text: "Je tiens à exprimer ma gratitude à Fine Services pour leur excellent travail dans la rénovation de mon appartement. Ils ont fait un travail exceptionnel et je suis très satisfait du résultat final. Je les recommande à tous ceux qui recherchent des services de rénovation d'appartement de haute qualité."
        },
        {
            name: "Charlotte",
            text: "Merci à Fine Services pour leur travail exceptionnel dans la rénovation de mon espace extérieur. Ils ont fait un travail remarquable pour transformer mon jardin et ma terrasse en un endroit magnifique et accueillant. Je les recommande à tous ceux qui ont besoin de services de rénovation d'espace extérieur de qualité."
        },
        {
            name: "Antoine",
            text: "Je suis extrêmement reconnaissant envers Fine Services pour leur excellent travail dans la rénovation de mon espace de travail. Ils ont fait un travail exceptionnel pour transformer mon bureau et je les recommande vivement à tous ceux qui ont besoin de services de rénovation de bureau de qualité."
        },
        {
            name: "Nicolas",
            text: "Je tiens à remercier Fine Services pour leur professionnalisme et leur expertise dans la rénovation. Ils ont travaillé sur mon jardin avec une grande attention aux détails, et le résultat est tout simplement spectaculaire. Je recommande vivement leurs services à tous ceux qui cherchent à transformer leur espace extérieur."
        }
    ];

    const nextTestimonial = () => {
        setCurrentTestimonialIndex((prevIndex) => (prevIndex + 1) % testimonials.length);
    };

    const prevTestimonial = () => {
        setCurrentTestimonialIndex((prevIndex) => (prevIndex - 1 + testimonials.length) % testimonials.length);
    };

    return (
        <div className="testimonials-container">
            <h1>TÉMOIGNAGES</h1>
            <div className="testimonial-carousel">
                <button className="prev-btn" onClick={prevTestimonial}>&#10094;</button>
                <div className="testimonial">
                    <h3>{testimonials[currentTestimonialIndex].name}</h3>
                    <p>{testimonials[currentTestimonialIndex].text}</p>
                </div>
                <button className="next-btn" onClick={nextTestimonial}>&#10095;</button>
            </div>
            <div className="testimonial-dots">
                {testimonials.map((testimonial, index) => (
                    <span key={index} className={index === currentTestimonialIndex ? "dot active" : "dot"} onClick={() => setCurrentTestimonialIndex(index)}></span>
                ))}
            </div>
        </div>
    );
};

export default Témoignages;
