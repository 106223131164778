// Services.jsx
import React from 'react';
import '../styles/ServicesP.css';
import Interieur from '../assets/img/interieur.jpg';
import Espacevert1 from '../assets/img/espacevert1.jpg';
import Batiments from '../assets/img/Batiments.gif'
import Diogene from '../assets/img/diogene.jpg'
import debarras from '../assets/img/debarras.jpg'
import basemodulaire from '../assets/img/basemodulaire.jpg'
function Services() {
    return (
        <div className="equipe-container5">
            <div className="barre-container5">
                <h2>Decouvrez Nos Services</h2>
                <div className="barre-blue"></div>
            </div>
            <div className="content-container5">
                <div className="bloc-image5">
                    <img src={Interieur} alt="Nom de l'équipe" />
                </div>
                <div className="bloc-text5">
                    <h1>SERVICE INTERIEUR</h1>
                    <p>Rénovation de Cuisine : La cuisine est le cœur de la maison, et notre équipe peut vous aider à créer une cuisine fonctionnelle, élégante et adaptée à vos besoins. De la conception à l'installation, nous vous guiderons à travers chaque étape du processus pour vous assurer d'obtenir la cuisine de vos rêves.</p>

                    <p>Rénovation de Salle de Bains : Transformez votre salle de bains en un espace luxueux et relaxant avec nos services de rénovation de salle de bains. Que vous souhaitiez simplement mettre à jour les appareils sanitaires ou repenser complètement la disposition de votre salle de bains, nous avons les compétences et l'expertise nécessaires pour réaliser votre vision.</p>

                    <p>Aménagement Intérieur : Vous souhaitez donner un coup de frais à votre espace de vie ? Notre équipe peut vous aider à repenser l'agencement de votre maison pour maximiser l'espace et créer une ambiance harmonieuse et accueillante.</p>

                    <p>Revêtements de Sol et de Mur : Des sols en bois élégants aux carrelages sophistiqués, nous proposons une gamme complète de revêtements de sol et de mur pour ajouter une touche d'élégance à votre maison.</p>

                    <p> Peinture Intérieure : La peinture est l'un des moyens les plus simples et les plus efficaces de transformer l'apparence d'une pièce. Notre équipe de peintres professionnels peut vous aider à choisir les couleurs parfaites et à réaliser une finition impeccable pour une apparence à la fois fraîche et durable.</p>
                </div>
            </div>
            <div className="content-container5">
                <div className="bloc-image5">
                    <img src={Espacevert1} alt="Nom de l'équipe" />
                </div>
                <div className="bloc-text5">
                    <h1>SERVICE ESPACE VERT</h1>
                    <p>Entretien de Jardin : Nos experts en espaces verts sont là pour entretenir votre jardin et le maintenir en parfait état tout au long de l'année. De la tonte de pelouse à la taille des arbustes, nous veillons à ce que votre jardin soit toujours beau et accueillant.</p>
                </div>
            </div>
            <div className="content-container5">
                <div className="bloc-image5">
                    <img src={Batiments} alt="Nom de l'équipe" />
                </div>
                <div className="bloc-text5">
                    <h1>SERVICE DES BATIMENTS</h1>
                    <p>Réaménagement Intérieur : Donnez une nouvelle vie à l'intérieur de votre bâtiment avec notre service de réaménagement intérieur. Que ce soit pour moderniser les espaces de vie, créer de nouvelles configurations pour une utilisation plus efficace de l'espace, ou simplement rafraîchir l'apparence intérieure, nous sommes là pour répondre à vos besoins</p>
                    <p>Peinture et Revêtements : Rafraîchissez l'apparence de votre bâtiment avec notre service de peinture et de revêtements. Que ce soit pour repeindre les murs extérieurs, rafraîchir les façades, ou appliquer des revêtements protecteurs, notre équipe de professionnels peut vous aider à choisir les meilleures solutions pour améliorer l'esthétique et la durabilité de votre bâtiment</p>
                </div>
            </div>
            <div className="content-container5">
                <div className="bloc-image5">
                    <img src={Diogene} alt="Nom de l'équipe" />
                </div>
                <div className="bloc-text5">
                    <h1>NETTOYAGE EXTREME(DIOGENES)</h1>
                    <p>Réhabilitation de l'Habitat Diogène : Offrez une nouvelle vie aux maisons Diogène avec notre service de réhabilitation. Nous sommes spécialisés dans la restauration et la transformation de ces habitats particuliers pour les rendre confortables, fonctionnels et esthétiquement agréables, tout en préservant leur caractère unique</p>
                </div>
            </div>
            <div className="content-container5">
                <div className="bloc-image5">
                    <img src={debarras} alt="Nom de l'équipe" />
                </div>
                <div className="bloc-text5">
                    <h1>DEBARRAS  PARKINGS, CAVES, ET PLUS ENCORE</h1>
                    <p>FINE SERVICESnp offre un service de débarras complet pour tous vos espaces de stockage, parkings, caves et autres zones encombrées. Que vous soyez propriétaire d'un entrepôt commercial, d'un parking souterrain, ou que vous ayez une cave encombrée de vieux objets, nous sommes là pour vous aider à retrouver de l'espace et à vous débarrasser du désordre.</p>
                    <p>Notre équipe qualifiée est spécialisée dans le débarras des grands espaces, et nous disposons des outils et de l'expertise nécessaires pour gérer les projets de toutes tailles. Que vous ayez besoin de débarrasser des palettes, des équipements obsolètes,tout autre type de débris, nous sommes prêts à intervenir rapidement et efficacement.</p>
                </div>
            </div>
            <div className="content-container5">
                <div className="bloc-image5">
                    <img src={basemodulaire} alt="Nom de l'équipe" />
                </div>
                <div className="bloc-text5">
                    <h1>NETTOYAGE DE VOS BASES VIE MODULAIRES ET
                        BUNGALOWS DE CHANTIER
                    </h1>
                    <p>Vous cherchez à installer des bases vie modulaires pour vos équipes sur vos chantiers ?
                        FINE SERVICES est là pour vous. Nous offrons un service complet d'entretien et de nettoyage pour assurer
                        le confort et la propreté de vos lieux de vie et de repos. Notre offre spécifique pour les chantiers tient
                        compte des exigences du secteur et garantit une désinfection complète des équipements sanitaires et des espaces de vie.
                        Faites confiance
                        à FINE SERVICES pour maintenir un environnement propre et confortable sur vos chantiers.
                    </p>
                </div>
            </div>
        </div>
    );
}

export default Services;
