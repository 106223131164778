import React, { useState } from 'react';
import { FaEnvelope } from 'react-icons/fa';
import '../styles/Contact.css';
import cameroun from '../assets/img/Cameroun.png';
import france from '../assets/img/France.png';

function Contact() {
    const [modalVisible, setModalVisible] = useState(false);

    const openModal = () => {
        setModalVisible(true);
    };

    const closeModal = () => {
        setModalVisible(false);
    };

    return (
        <div className='contact-container'>
            <div className='contact-left'>
                <h1><img src={france} alt="Logo France" className='flag' /> Envoyez-nous un email depuis la France</h1>
                <div className='gmail-icon-container'>
                    <a href="mailto:contact@fineservices.fr">
                        <FaEnvelope className='gmail-icon' />
                    </a>
                </div>
            </div>
            <div className='contact-right'>
                <h1><img src={cameroun} alt="Logo Cameroun" className='flag' /> Envoyez-nous un email depuis le Cameroun</h1>
                <div className='gmail-icon-container'>
                    <a href="mailto:contact@fineservices.fr">
                        <FaEnvelope className='gmail-icon' />
                    </a>
                </div>
            </div>
            <button className="contact-button" onClick={openModal}>Afficher les horaires d'ouverture</button>
            {modalVisible && (
                <div className="modal">
                    <div className="modal-main">
                        <div className="modal-content">
                            <button className="modal-close" onClick={closeModal}>&times;</button>
                            <h2>Horaires d’Ouverture</h2>
                            <p>Lundi - 9h-17h</p>
                            <p>Mardi - 9h-17h</p>
                            <p>Mercredi - 9h-17h</p>
                            <p>Jeudi - 9h-17h</p>
                            <p>Vendredi - 9h-17h</p>
                            <p>Samedi/Dimanche - Fermé</p>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default Contact;
