import React, { useState } from 'react';
import '../styles/RealisationsP.css';

// Import des images pour la galerie "Diogène"
import imaged1 from '../assets/img/realisations/Diogene/image1.jpg';
import imaged2 from '../assets/img/realisations/Diogene/image2.jpg';
import imaged3 from '../assets/img/realisations/Diogene/image3.jpg';
import imaged4 from '../assets/img/realisations/Diogene/image4.jpg';
import imaged5 from '../assets/img/realisations/Diogene/image5.jpg';
import imaged6 from '../assets/img/realisations/Diogene/image6.jpg';
import imaged7 from '../assets/img/realisations/Diogene/image7.jpg';
import imaged8 from '../assets/img/realisations/Diogene/image8.jpg';
import imaged9 from '../assets/img/realisations/Diogene/image9.jpg';
import imaged10 from '../assets/img/realisations/Diogene/image10.jpg';
import imaged11 from '../assets/img/realisations/Diogene/image11.jpg';
import imaged12 from '../assets/img/realisations/Diogene/image12.jpg';
import imaged13 from '../assets/img/realisations/Diogene/image13.jpg';
import imaged14 from '../assets/img/realisations/Diogene/image14.jpg';
import imaged15 from '../assets/img/realisations/Diogene/image15.jpg';
import imaged16 from '../assets/img/realisations/Diogene/image16.jpg';

// Import des images pour la galerie "Interieur"
import imager1 from '../assets/img/realisations/interieur/image1.jpg';
import imager2 from '../assets/img/realisations/interieur/image2.jpg';
import imager3 from '../assets/img/realisations/interieur/image3.jpg';
import imager4 from '../assets/img/realisations/interieur/image4.jpg';
import imager5 from '../assets/img/realisations/interieur/image5.jpg';
import imager6 from '../assets/img/realisations/interieur/image6.jpg';
import imager7 from '../assets/img/realisations/interieur/image7.jpg';
import imager8 from '../assets/img/realisations/interieur/image8.jpg';
import imager9 from '../assets/img/realisations/interieur/image9.jpg';
import imager10 from '../assets/img/realisations/interieur/image10.jpg';
import imager11 from '../assets/img/realisations/interieur/image11.jpg';
import imager12 from '../assets/img/realisations/interieur/image12.jpg';
import imager13 from '../assets/img/realisations/interieur/image13.jpg';
import imager14 from '../assets/img/realisations/interieur/image14.jpg';
import imager15 from '../assets/img/realisations/interieur/image15.jpg';
import imager16 from '../assets/img/realisations/interieur/image16.jpg';

// Import des images pour la galerie "Espace vert"
import imagev1 from '../assets/img/realisations/esp_vert/image1.jpg';
import imagev2 from '../assets/img/realisations/esp_vert/image2.jpg';
import imagev3 from '../assets/img/realisations/esp_vert/image3.jpg';
import imagev4 from '../assets/img/realisations/esp_vert/image4.jpg';
import imagev5 from '../assets/img/realisations/esp_vert/image5.jpg';
import imagev6 from '../assets/img/realisations/esp_vert/image6.jpg';
import imagev7 from '../assets/img/realisations/esp_vert/image7.jpg';
import imagev8 from '../assets/img/realisations/esp_vert/image8.jpg';
import imagev9 from '../assets/img/realisations/esp_vert/image9.jpg';
import imagev10 from '../assets/img/realisations/esp_vert/image10.jpg';
import imagev11 from '../assets/img/realisations/esp_vert/image11.jpg';
import imagev12 from '../assets/img/realisations/esp_vert/image12.jpg';
import imagev13 from '../assets/img/realisations/esp_vert/image13.jpg';
import imagev14 from '../assets/img/realisations/esp_vert/image14.jpg';
import imagev15 from '../assets/img/realisations/esp_vert/image15.jpg';
import imagev16 from '../assets/img/realisations/esp_vert/image16.jpg';

// Import des images pour la galerie "Débarras"
import imagede1 from '../assets/img/realisations/debarras/image1.jpg';
import imagede2 from '../assets/img/realisations/debarras/image2.jpg';
import imagede3 from '../assets/img/realisations/debarras/image3.jpg';
import imagede4 from '../assets/img/realisations/debarras/image4.jpg';
import imagede5 from '../assets/img/realisations/debarras/image5.jpg';
import imagede6 from '../assets/img/realisations/debarras/image6.jpg';
import imagede7 from '../assets/img/realisations/debarras/image7.jpg';

function Realisations() {
    const [modalOpen, setModalOpen] = useState(false);
    const [selectedCategory, setSelectedCategory] = useState('');
    const [fullscreenImage, setFullscreenImage] = useState('');

    const handleOpenModal = (category) => {
        setSelectedCategory(category);
        setModalOpen(true);
    };

    const handleCloseModal = () => {
        setModalOpen(false);
        setFullscreenImage('');
    };

    const handleOpenFullscreen = (imageUrl) => {
        setFullscreenImage(imageUrl);
    };

    const handleCloseFullscreen = () => {
        setFullscreenImage('');
    };

    // Définition des images pour la galerie "Diogène"
    const imagesDiogene = [
        imaged1,
        imaged2,
        imaged3,
        imaged4,
        imaged5,
        imaged6,
        imaged7,
        imaged8,
        imaged9,
        imaged10,
        imaged11,
        imaged12,
        imaged13,
        imaged14,
        imaged15,
        imaged16
    ];

    // Définition des images pour la galerie "Interieur"
    const imagesInterieur = [
        imager1,
        imager2,
        imager3,
        imager4,
        imager5,
        imager6,
        imager7,
        imager8,
        imager9,
        imager10,
        imager11,
        imager12,
        imager13,
        imager14,
        imager15,
        imager16
    ];

    // Définition des images pour la galerie "Espace vert"
    const imagesEspaceVert = [
        imagev1,
        imagev2,
        imagev3,
        imagev4,
        imagev5,
        imagev6,
        imagev7,
        imagev8,
        imagev9,
        imagev10,
        imagev11,
        imagev12,
        imagev13,
        imagev14,
        imagev15,
        imagev16
    ];

    // Définition des images pour la galerie "Débarras"
    const imagesDebarras = [
        imagede1,
        imagede2,
        imagede3,
        imagede4,
        imagede5,
        imagede6,
        imagede7
    ];

    // Images par défaut pour les autres galeries
    const defaultImages = Array.from({ length: 16 }, (_, index) => `https://via.placeholder.com/150x250?text=Image${index + 1}`);

    // Utilisation des images correspondantes en fonction de la galerie sélectionnée
    let images;
    if (selectedCategory === "Diogène") {
        images = imagesDiogene;
    } else if (selectedCategory === "Interieur") {
        images = imagesInterieur;
    } else if (selectedCategory === "Espace vert") {
        images = imagesEspaceVert;
    } else if (selectedCategory === "Débarras") {
        images = imagesDebarras;
    } else {
        images = defaultImages;
    }

    // Message à afficher lorsque la catégorie sélectionnée n'a pas d'images disponibles
    const noImagesMessage = "Les images sont en cours de chargement. Veuillez revenir plus tard. Merci !";

    return (
        <div className="realisations-container">
            <h1 className="realisations-title">Réalisations De FINE SERVICES</h1>
            <div className="gallery-buttons">
                <div onClick={() => handleOpenModal("Interieur")} className="gallery-button">
                    <div className="category-icon">Galerie Interieur</div>
                </div>
                <div onClick={() => handleOpenModal("Espace vert")} className="gallery-button">
                    <div className="category-icon">Galerie Espace-vert</div>
                </div>
                <div onClick={() => handleOpenModal("Débarras")} className="gallery-button">
                    <div className="category-icon">Galerie Débarras</div>
                </div>
                <div onClick={() => handleOpenModal("Diogène")} className="gallery-button">
                    <div className="category-icon">Galerie Diogène</div>
                </div>
            </div>
            {modalOpen && (
                <div className="modal">
                    <div className="modal-content">
                        <span className="close" onClick={handleCloseModal}>&times;</span>
                        <h2>{selectedCategory}</h2>
                        {images.length === 0 ? (
                            <p style={{ color: 'red' }}>{noImagesMessage}</p>
                        ) : (
                            <div className="image-grid">
                                {images.map((imageUrl, index) => (
                                    <div key={index} className="image-container" onClick={() => handleOpenFullscreen(imageUrl)}>
                                        <img src={imageUrl} alt={`Image ${index}`} />
                                    </div>
                                ))}
                            </div>
                        )}
                        <button className="close-modal-button" onClick={handleCloseModal}>Fermer</button>
                    </div>
                </div>
            )}
            {fullscreenImage && (
                <div className="fullscreen-modal" onClick={handleCloseFullscreen}>
                    <div className="fullscreen-content">
                        <span className="close" onClick={handleCloseFullscreen}>&times;</span>
                        <img src={fullscreenImage} alt="Image en plein écran" />
                    </div>
                </div>
            )}
        </div>
    );
}

export default Realisations;
