import React from 'react';
import '../styles/Realisations.css'; // Assurez-vous d'avoir un fichier Realisations.css pour styliser votre composant
import { Link } from 'react-router-dom';
import video1 from '../assets/videos/video1.mp4'
import video2 from '../assets/videos/video2.mp4'
import video3 from '../assets/videos/video3.mp4'
import video4 from '../assets/videos/video4.mp4'

const Realisations = () => {
    return (
        <div className="realisations-container">
            <h1 style={{ color: "white" }}>NOS RÉALISATIONS</h1>
            <hr className="barre" />
            <div className="blocs-container">
                {/* Blocs pour les vidéos */}
                <div className="bloc">
                    <video controls className='video_service'>
                        <source src={video1} type="video/mp4" />
                    </video>
                </div>
                <div className="bloc">
                    <video controls className='video_service'>
                        <source src={video2} type="video/mp4" />
                    </video>
                </div>
                <div className="bloc">
                    <video controls className='video_service'>
                        <source src={video3} type="video/mp4" />
                    </video>
                </div>
                <div className="bloc">
                    <video controls className='video_service'>
                        <source src={video4} type="video/mp4" />
                    </video>
                </div>
            </div>
            {/* Lien "Voir plus" */}
            <div className="voir-plus">
                <Link to='/Realisations' >Voir plus</Link>
            </div>
        </div>
    );
};

export default Realisations;
