import React from 'react';
import '../styles/Apropos.css';

function Apropos() {
    return (
        <section className="apropos-container">
            <h1>À propos de Fine Services</h1>
            <p>Fine Services est une entreprise spécialisée dans la rénovation haut de gamme, offrant des services d'une qualité exceptionnelle en Europe et au Cameroun.</p>
            <p>En France, notre siège social est basé à Viry-Châtillon, dans la région Île-de-France. Au Cameroun, notre adresse se trouve à Tsinga. Nous avons établi une solide réputation dans l'industrie de la rénovation grâce à notre expertise et à notre engagement envers la satisfaction des clients.</p>
            <p>Nos services englobent la rénovation des bâtiments et des espaces verts, réalisée avec une attention particulière portée aux détails et à la qualité artisanale.</p>
            <p>Que vous envisagiez une rénovation complète ou partielle, notre équipe expérimentée vous accompagnera tout au long du processus, de la conception à la réalisation, afin de transformer votre espace en un lieu de vie ou de travail exceptionnel.</p>
            <p>Chez Fine Services, nous croyons en l'excellence dans tout ce que nous entreprenons, et nous nous engageons à offrir à nos clients des résultats qui dépassent leurs attentes.</p>
        </section>
    );
}

export default Apropos;
