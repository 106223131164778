import React from 'react';
import EquipeImg from '../assets/equipe.jpg';
import '../styles/Equipe.css';

function Equipe() {
    return (
        <section className="equipe-container">
            <header className="barre-container">
                <h2>Découvrez Notre Équipe</h2>
                <div className="barre-blue"></div>
            </header>
            <div className="content-container">
                <div className="bloc-image">
                    <img src={EquipeImg} alt="Notre équipe dédiée à vos besoins de nettoyage et rénovation" loading="lazy" />
                </div>
                <div className="bloc-text">
                    <h1>L'équipe idéale</h1>
                    <p>
                        Pour vos besoins de rénovation et de nettoyage, confiez votre projet en toute confiance à Fine Services. Nous sommes une entreprise leader dans notre domaine, dotée d'une équipe d'experts dévoués. Nous nous engageons à fournir des solutions sur mesure et efficaces pour répondre à vos besoins. Avec notre focus sur la qualité et l'efficacité, nous sommes votre partenaire de confiance pour la transformation de vos espaces.
                    </p>
                </div>
            </div>
        </section>
    );
}

export default Equipe;
