import React, { useState } from 'react';
import '../styles/PourquoiNous.css';

function PourquoiNous() {
    const [modalOpen1, setModalOpen1] = useState(false);
    const [modalContent1, setModalContent1] = useState('');

    const openModal1 = (content) => {
        setModalContent1(content);
        setModalOpen1(true);
    };

    const closeModal1 = () => {
        setModalOpen1(false);
    };

    return (
        <div className="why-us-container1">
            <h1>Pourquoi Nous Choisir</h1>
            <div className="barre1"></div>
            <p className="description1">
                Avec un personnel jeune et talentueux, nous sommes toujours disposés à relever de nouveaux défis, pour le plus grand plaisir de notre aimable clientèle.
            </p>
            <div className="blocs-container1">
                <div className="bloc1" onClick={() => openModal1('mission')}>
                    <h2>NOTRE MISSION</h2>
                </div>
                <div className="bloc1" onClick={() => openModal1('vision')}>
                    <h2>NOTRE VISION</h2>
                </div>
                <div className="bloc1" onClick={() => openModal1('valeurs')}>
                    <h2>NOS VALEURS</h2>
                </div>
            </div>
            {modalOpen1 && (
                <div className="modal-background1">
                    <div className="modal1">
                        <span className="close1" onClick={closeModal1}>&times;</span>
                        {modalContent1 === 'mission' && (
                            <>
                                <h3>Notre Mission</h3>
                                <p>
                                    Fine Services est une entreprise spécialisée dans la rénovation des bâtiments, des espaces intérieurs, des espaces verts et des bureaux. Nous sommes engagés à fournir des services de qualité pour satisfaire notre aimable clientèle.
                                </p>
                                <p>
                                    Notre mission est de transformer les espaces et de répondre aux besoins de nos clients avec des solutions sur mesure et innovantes.
                                </p>
                            </>
                        )}
                        {modalContent1 === 'vision' && (
                            <>
                                <h3>Notre Vision</h3>
                                <p>
                                    Notre vision est de devenir un leader reconnu dans le domaine de la rénovation en offrant des services exceptionnels et en créant des espaces inspirants pour nos clients.
                                </p>
                                <p>
                                    Nos valeurs reposent sur l'intégrité, l'excellence et la satisfaction du client. Nous nous engageons à maintenir des normes élevées dans tout ce que nous faisons.
                                </p>
                            </>
                        )}
                        {modalContent1 === 'valeurs' && (
                            <>
                                <h3>Nos Valeurs</h3>
                                <p>
                                    Chez Fine Services, nos valeurs guident notre comportement, nos décisions et notre culture d'entreprise. Elles représentent ce en quoi nous croyons et ce que nous défendons dans notre travail quotidien.
                                </p>
                                <p>
                                    Intégrité, Excellence, Collaboration, Engagement envers la Durabilité, Satisfaction Client.
                                </p>
                            </>
                        )}
                    </div>
                </div>
            )}
        </div>
    );
}

export default PourquoiNous;
