import React from 'react';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import { Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import Contact from './pages/Contact';
import Apropos from './pages/Apropos'
import Realisations from './pages/Realisations';
import Services from './pages/Services';

function App() {
  return (
    <div>
      <Navbar />
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/Contact' element={<Contact />} />
        <Route path='/Apropos' element={<Apropos />} />
        <Route path='/Realisations' element={<Realisations />} />
        <Route path='/Services' element={<Services />} />
      </Routes>
      <Footer />
    </div>
  )
}

export default App
