import React from 'react'
import Carousel from '../components/Carousel';
import Services from '../components/Services';
import Realisations from '../components/Realisations';
import Témoignages from '../components/Témoignages'
import Equipe from '../components/Equipe';
import PourquoiNous from '../components/PourquoiNous';

function Home() {
    return (
        <div>
            <Carousel />
            <Services />
            <Realisations />
            <Témoignages />
            <Equipe />
            <PourquoiNous />
        </div>
    )
}

export default Home
