import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import '../styles/Navbar.css';
import Logo from '../assets/img/LOGO-01.png';

const Navbar = () => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleNavbar = () => {
        setIsOpen(!isOpen);
    };

    const closeNavbar = () => {
        setIsOpen(false);
    };

    return (
        <nav className={isOpen ? 'navbar blur' : 'navbar'}>
            <div className="container">
                <Link to='/'>
                    <img src={Logo} alt="fotos fine services" className="logo" />
                </Link>
                <div className="toggle" onClick={toggleNavbar}>
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
                <div className={isOpen ? 'navbar-links active' : 'navbar-links'}>
                    <Link to="/" onClick={closeNavbar}>Accueil</Link>
                    <Link to="/services" onClick={closeNavbar}>Services</Link>
                    <Link to="/realisations" onClick={closeNavbar}>Realisations</Link>
                    <Link to="/Apropos" onClick={closeNavbar}>À propos</Link>
                    <Link to="/contact" onClick={closeNavbar}>Contact</Link>
                </div>
            </div>
        </nav>
    );
};

export default Navbar;
